import React from "react";
import { Box, Typography, Button, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import theme from "../theme";
import { fetchFeaturedProductSingle } from "./fetchProductDetails";
import { motion } from "framer-motion";

// Animation variants
const variants = {
  hidden: { opacity: 0, y: 50 }, // Start hidden with a vertical offset
  visible: (index) => ({
    opacity: 1,
    y: 0,
    transition: {
      delay: 0.2, // Add delay based on index to stagger the cards
      duration: 0.5,
      ease: "easeInOut",
    },
  }),
};

const FeaturedProductComponent = () => {
  const navigate = useNavigate();
  const [featuredProduct, setFeaturedProduct] = React.useState({});
  //fetch products from database
  React.useEffect(() => {
    const getProducts = async () => {
      try {
        const data = await fetchFeaturedProductSingle();
        // console.log("BREAKPOINT= " + JSON.stringify(data[0].product, null, 2));
        setFeaturedProduct(data[0].product);
      } catch (error) {
        // handle error
      }
    };

    getProducts();
  }, []);

  return (
    <motion.div
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true, amount: 0.3 }} // Only animate once, when 30% of the element is visible
      // custom={index}
      variants={variants}
    >
      <ThemeProvider theme={theme}>
        <Box
          sx={{
            width: "100%",
            height: "100%",
            backgroundColor: "#f8f5f2",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Grid container spacing={0} sx={{ maxWidth: "90%" }}>
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                p: 4,
              }}
            >
              <Typography
                variant="h2"
                gutterBottom
                sx={{ color: "text.secondary", fontWeight: "bold" }}
              >
                {featuredProduct.featured_image_title}
              </Typography>
              <Typography
                variant="h6"
                gutterBottom
                sx={{ color: "text.secondary" }}
              >
                {featuredProduct.description}
              </Typography>
              <Button
                variant="contained"
                sx={{ backgroundColor: "palette.primary", mt: 2 }}
                onClick={() => {
                  navigate(
                    `/products/${featuredProduct.listing_id}/${featuredProduct.design_id}`
                  );
                }}
              >
                Shop Now
              </Button>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  width: "100%",
                  height: "100%",
                  overflow: "hidden",
                  position: "relative",
                }}
              >
                <img
                  src={featuredProduct.featured_image_url}
                  alt="Product"
                  style={{ width: "100%", height: "100%", objectFit: "cover" }}
                />
              </Box>
            </Grid>
          </Grid>
          {/* <Box
        sx={{
          position: "absolute",
          top: "50%",
          right: "10px",
          backgroundColor: "white",
          padding: "10px 20px",
          transform: "rotate(90deg)",
          transformOrigin: "right top 0",
        }}
      >
        <Typography variant="body2" sx={{ color: "#8c4b32" }}>
          Get 10% Off
        </Typography>
      </Box> */}
        </Box>
      </ThemeProvider>
    </motion.div>
  );
};

export default FeaturedProductComponent;
