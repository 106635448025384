import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

function TemplateIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 512 512">
      <path
        d="M415.4,450.2c6,0,10.9-4.9,10.9-10.9V72.7c0-6-4.9-10.9-10.9-10.9H96.6c-6,0-10.9,4.9-10.9,10.9v366.6
		c0,6,4.9,10.9,10.9,10.9L415.4,450.2L415.4,450.2z M295.7,435.2c3.8-39.3,18.1-98.5,57.8-101.1c6.7,6.4,30.3,33.5,32,101.1H295.7z
		 M110.3,435.2h-9.6V76.8h310.7v358.5h-10.8c-1.8-78.1-31.9-108-38.6-113.7c-1.7-2.6-6.2-9.2-13.2-16.8c2.9-3,4.7-7.2,4.7-11.6
		v-98.3c0-0.1,0-0.2,0-0.4c0-1-1.3-24.3-13.7-47.9c-11.7-22.3-35.6-48.8-83.3-48.8c-24.4,0-46.9,9.4-63.5,26.5
		c-16.1,16.6-25,38.5-25,61.7v109.7c-2.3,3.5-6.9,11-10.7,21.6C149.3,325.2,112.8,365,110.3,435.2z M280.5,140
		c-29.5-29.4-78.4-11.7-78.4,28.8v40.3c0,15.9,6.6,30.1,16.8,38.9c0,3.6,0.2,5.9-1,9.4c-1.4,4.2-4.2,7.9-8.2,10.4l-26.9,16.8v-98.5
		c0-41.1,32.3-73.2,73.5-73.2c32.1,0,55.6,13.6,69.8,40.3c10.5,19.8,12.1,40,12.2,41.9v98c0,0.7-0.4,1.3-1,1.5
		c-0.5,0.2-3.4,1.5-40,17c-1.1,0.5-2.3-0.3-2.3-1.5c0-22.4,0-87.6,0-98l0,0v-36.7C295.1,162.3,290,149.6,280.5,140z M248.6,243.4
		c-19.6,0-31.5-15.3-31.5-34.3v-40.3c0-15.1,11.6-27.5,26.3-28.4c19.4-1.2,36.8,14.6,36.8,35v36.7
		C280.1,229,266.9,243.3,248.6,243.4L248.6,243.4z M280.1,246.2v60c-51.3,52.6-85.9,30.9-60.4-27.1c7.4-5.3,12.5-13.6,13.9-23
		C249.5,261.2,267.6,257.8,280.1,246.2z M167.5,435.2h-42.1c1.5-38.2,14.1-66.8,25.4-84.9c-0.8,23.3,7.8,45.8,14.4,54.9l0,0
		c2.1,2.8,4.6,5.2,7.6,6.9C169.5,418.8,167.6,426.8,167.5,435.2z M182.5,435.2c0.2-6.8,1.8-13.8,5.4-19.8h20.7
		c13.3,0,24.2,8.3,28.2,19.8H182.5z M280.6,435.2h-28.2c-3.6-17.7-20.3-34.8-43.8-34.8h-23.5c-3,0-6.1-1.5-7.9-4.1
		c-1.5-2.1-5.3-10.6-6.6-14.3c-9.9-27.8-4-57.5,10-78.4c7-4.4,11.8-7.4,18.8-11.8c-7.4,28.1-3.1,54.6,25.5,58.7
		c21.8,3.1,44.4-13.2,60.3-28.4c4.7,4.5,11.8,5.9,18,3.2L335,312c3,3.1,5.6,6,7.7,8.7C300.2,331.1,284.4,391.6,280.6,435.2
		L280.6,435.2z"
      />
      <path
        d="M273.5,481.1H444c7.3,0,13.2-5.9,13.2-13.2V44.1c0-7.3-5.9-13.2-13.2-13.2H68c-7.3,0-13.2,5.9-13.2,13.2V468
		c0,7.3,5.9,13.2,13.2,13.2h170.5c4.1,0,7.5-3.4,7.5-7.5s-3.4-7.5-7.5-7.5H69.8V45.9h372.4v420.2H273.5c-4.1,0-7.5,3.4-7.5,7.5
		S269.4,481.1,273.5,481.1L273.5,481.1z"
      />
      <path
        d="M480.6,246c4.1,0,7.5-3.4,7.5-7.5V17.1C488.1,7.7,480.4,0,471,0H41c-9.4,0-17.1,7.7-17.1,17.1V495
		c0,9.4,7.7,17.1,17.1,17.1h430c9.4,0,17.1-7.7,17.1-17.1V273.5c0-4.1-3.4-7.5-7.5-7.5s-7.5,3.4-7.5,7.5v221.4
		c0,1.1-0.9,2.1-2.1,2.1H41c-1.1,0-2.1-0.9-2.1-2.1V17.1c0-1.1,0.9-2.1,2.1-2.1h430c1.1,0,2.1,0.9,2.1,2.1v221.4
		C473.1,242.6,476.5,246,480.6,246L480.6,246z"
      />
    </SvgIcon>
  );
}

export default TemplateIcon;
