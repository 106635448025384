import { Box } from "@mui/material";
import React from "react";
import { CanvasContext } from "../CanvasContext";
import CustomProductPreview from "./CustomProductPreview";
import { useParams } from "react-router-dom";

const CustomizeProductCanvas = (props) => {
  const { productBrand } = useParams();
  const { productCanvasRef, productColorOption } =
    React.useContext(CanvasContext);

  let canvasTop;
  if (productBrand === "Bella Canvas 3001") {
    canvasTop = "50%";
  } else if (productBrand === "Gildan 18000") {
    canvasTop = "50%";
  } else if (productBrand === "Gildan 18500") {
    canvasTop = "45%";
  }

  return (
    <>
      <Box
        sx={{
          position: "relative",
          // display: "flex",
          // justifyContent: "center",
          // alignItems: "center",
          mt: "2%",
          mb: "%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            // position: "absolute",
            // backgroundColor: "beige",
            left: 0,
            top: 0,
          }}
        >
          <img
            src={productColorOption}
            width={"80%"}
            height={"auto"}
            id="shirt-image"
            // crossOrigin="anonymous"
            // style={{ display: "none" }}
          />
        </Box>
        <Box
          sx={{
            position: "absolute",
            left: "50%",
            top: canvasTop,
            transform: "translate(-50%, -50%)",
            zIndex: 1,
            // backgroundColor: "pink",
            // border: "2px dashed gray", // Dashed border
          }}
        >
          <canvas id={"product-canvas"} ref={productCanvasRef}></canvas>
        </Box>
        {/* <Button variant="contained" color="primary" onClick={saveCombinedImage}>
          Save Combined Image
        </Button>
        <canvas ref={combinedCanvasRef} style={{ display: "none" }}></canvas> */}
      </Box>
      <Box
        sx={{
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
          zIndex: -1,

          // backgroundColor: "pink",
          // border: "2px dashed gray", // Dashed border
        }}
      >
        <CustomProductPreview
          closeButton={props.closeButton}
          confirmDesign={props.confirmDesign}
          setConfirmDesign={props.setConfirmDesign}
          previewRatio={props.previewRatio}
        />
      </Box>

      {/* {productColor.img} */}
    </>
  );
};

export default CustomizeProductCanvas;
