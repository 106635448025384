// CanvasContext.js
import React, { createContext, useRef, useState } from "react";

export const CanvasContext = createContext();

export const CanvasProvider = ({ children }) => {
  const canvasRef = useRef(null);
  const productCanvasRef = useRef(null);
  const [canvasRefTemplate, setCanvasRefTemplate] = useState(null);
  const [fabricCanvas, setFabricCanvas] = useState(null);
  const [temp, setTemp] = useState("");
  const [canvasAspectRatio, setCanvasAspectRatio] = useState(1);
  const [imgAspectRatio, setImgAspectRatio] = useState(1);
  const [scaleGlobal, setScaleGlobal] = useState(1);
  const [open, setOpen] = useState(false);
  const [saveClick, setSaveClick] = useState(false);
  const [imgURL, setImgURL] = useState("");
  const [fontSizing, setFontSizing] = React.useState(500);
  const [text, setText] = React.useState("");
  const [productColorOption, setProductColorOption] = useState(""); //to change the image source for product color
  const [saveClickProduct, setSaveClickProduct] = useState(false);
  const [fabricCanvasGlobal, setFabricCanvasGlobal] = useState(null);
  const fabricCanvasPreviewRef = useRef(null);
  const [productCanvasActive, setProductCanvasActive] = useState(false);
  const [productColor, setProductColor] = useState({});

  const [productImgURL, setProductImgURL] = useState("");
  const [shippingDetails, setShippingDetails] = useState({
    name: "",
    address: "",
    city: "",
    state: "",
    postalCode: "",
    country: "",
    email: "",
  });
  // const [textDetails, setTextDetails] = useState({
  //   font: "",
  //   size: "",
  //   color: "",
  //   fontWeight: "",
  // });
  const [font, setFont] = React.useState("Ayar Juno");
  const [formats, setFormats] = React.useState(() => []);
  const [textColor, setTextColor] = React.useState("#ffffff");
  // const [imgWidth, setImgWidth] = useState(0);
  // const [imgHeight, setImgHeight] = useState(0);

  // Any additional functions to manipulate the canvas

  return (
    <CanvasContext.Provider
      value={{
        fontSizing,
        setFontSizing,
        textColor,
        setTextColor,
        formats,
        setFormats,
        font,
        setFont,
        imgURL,
        setImgURL,
        saveClick,
        setSaveClick,
        canvasRef,
        canvasRefTemplate,
        setCanvasRefTemplate,
        fabricCanvas,
        setFabricCanvas,
        temp,
        setTemp,
        canvasAspectRatio,
        setCanvasAspectRatio,
        imgAspectRatio,
        setImgAspectRatio,
        scaleGlobal,
        setScaleGlobal,
        open,
        setOpen,
        productCanvasRef,
        text,
        setText,
        productColorOption,
        setProductColorOption,
        saveClickProduct,
        setSaveClickProduct,
        fabricCanvasGlobal,
        setFabricCanvasGlobal,
        fabricCanvasPreviewRef,
        productCanvasActive,
        setProductCanvasActive,
        productColor,
        setProductColor,
        productImgURL,
        setProductImgURL,
        shippingDetails,
        setShippingDetails,

        // imgWidth,
        // imgHeight,
        // setImgWidth,
        // setImgHeight,
      }}
    >
      {children}
    </CanvasContext.Provider>
  );
};
