import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { fabric } from "fabric";
import { useDispatch, useSelector } from "react-redux";
import { CanvasContext } from "../CanvasContext";
import { setProductPreviewMockupURL } from "../redux-slices/ImageSlice";
import { useParams } from "react-router-dom";

const baseURL = process.env.REACT_APP_API_BASE_URL;

const CustomProductPreview = (props) => {
  const dispatch = useDispatch();
  const { productBrand } = useParams();

  let previewRatio, previewRatioTop;
  if (productBrand === "Bella Canvas 3001") {
    previewRatio = 1.8;
    previewRatioTop = 2;
  } else if (productBrand === "Gildan 18000") {
    previewRatio = 1.8;
    previewRatioTop = 2;
  } else if (productBrand === "Gildan 18500") {
    previewRatio = 3;
    previewRatioTop = 2.3;
  }

  const designFileURL = useSelector((state) => state.image.designFileURL);
  // const canvasRef = useRef(null);
  const {
    productColorOption,
    fabricCanvasPreviewRef,
    setProductCanvasActive,
    setProductImgURL,
  } = React.useContext(CanvasContext);
  const [fabricGlobal, setFabricGlobal] = useState(null);
  const [imgProduct, setImgProduct] = useState("");

  setProductCanvasActive(true);

  console.log("BREAKPOINT= " + previewRatio);

  useEffect(() => {
    const imageUrl = encodeURIComponent(productColorOption);
    const fetchUrl = `${baseURL}/api/fetch-image/?image_url=${imageUrl}`;

    fetch(fetchUrl, {
      method: "GET",
      headers: {
        "Content-Type": "image/png",
      },
    })
      .then((response) => response.blob())
      .then((imageBlob) => {
        const imageObjectURL = URL.createObjectURL(imageBlob);
        setImgProduct(imageObjectURL);
      })
      .catch((error) => console.error("Error fetching image:", error));
  }, [productColorOption]);

  const adjustCanvasSize = (fabricCanvas) => {
    let viewportWidth = window.innerWidth;
    let viewportHeight = window.innerHeight;

    // Adjust canvas size based on device types
    if (viewportWidth < 600) {
      // Mobile
      viewportWidth = window.innerWidth * 0.3;
      viewportHeight = window.innerHeight * 0.3;
    } else if (viewportWidth >= 600 && viewportWidth < 960) {
      // Tablet
      viewportWidth = window.innerWidth * 0.3;
      viewportHeight = window.innerHeight * 0.3;
    } else if (viewportWidth >= 1500) {
      viewportWidth = window.innerWidth * 0.6;
      viewportHeight = window.innerHeight * 0.6;
    } else {
      // Desktop
      viewportWidth = window.innerWidth * 0.45;
      viewportHeight = window.innerHeight * 0.45;
    }

    // Calculate the scale factor
    const scaleX = viewportWidth / fabricCanvas.width;
    const scaleY = viewportHeight / fabricCanvas.height;
    const scaleToFit = Math.min(scaleX, scaleY);

    // setGlobalZoom(scaleToFit);
    // Set canvas zoom
    fabricCanvas.setZoom(scaleToFit);

    // Update canvas element size to match the scaled drawing area
    fabricCanvas.setWidth(fabricCanvas.width * scaleToFit);
    fabricCanvas.setHeight(fabricCanvas.height * scaleToFit);
  };

  // function downloadCanvasAsImage(fabricCanvas) {
  //   // Create a temporary canvas
  //   const tempCanvas = new fabric.StaticCanvas(null, {
  //     width: 2000,
  //     height: 2000,
  //     enableRetinaScaling: false,
  //   });
  //   const clonePromises = fabricCanvas.getObjects().map((obj) => {
  //     return new Promise((resolve) => {
  //       obj.clone((clone) => {
  //         // tempCanvas.add(clone);
  //         resolve(clone); // Resolve the promise with the clone
  //       });
  //     });
  //   });

  //   Promise.all(clonePromises).then((clonedObjects) => {
  //     // Instead of adding each clone in the clone callback,
  //     // we add them here in the correct order.
  //     clonedObjects.forEach((clone) => {
  //       tempCanvas.add(clone); // Add each clone to the tempCanvas
  //     });

  //     tempCanvas.renderAll(); // Render the tempCanvas after all clones are added
  //     // Further logic here, e.g., exporting the tempCanvas as an image
  //   });

  //   // Wait for all objects to clone before proceeding
  //   tempCanvas.renderAll();
  //   setTimeout(() => {
  //     // Export the temporary canvas to an image
  //     const dataURL = tempCanvas.toDataURL({
  //       format: "png",
  //       multiplier: 1, // Ensure it's exported at the canvas's original size
  //     });
  //     dispatch(setProductPreviewMockupURL(dataURL));

  //     // Create a link to download the image
  //     // const link = document.createElement("a");
  //     // link.download = "design.png";
  //     // link.href = dataURL;
  //     // document.body.appendChild(link);
  //     // link.click();
  //     // document.body.removeChild(link);

  //     // Clean up the temporary canvas

  //     tempCanvas.dispose();
  //   }, 500); // Adjust timeout as necessary to ensure canvas has finished rendering
  // }

  function downloadCanvasAsImage(fabricCanvas) {
    return new Promise((resolve, reject) => {
      // Create a temporary canvas
      const tempCanvas = new fabric.StaticCanvas(null, {
        width: 2000,
        height: 2000,
        enableRetinaScaling: false,
      });
      const clonePromises = fabricCanvas.getObjects().map((obj) => {
        return new Promise((resolve) => {
          obj.clone((clone) => {
            resolve(clone);
          });
        });
      });

      Promise.all(clonePromises)
        .then((clonedObjects) => {
          clonedObjects.forEach((clone) => {
            tempCanvas.add(clone);
          });

          tempCanvas.renderAll();

          setTimeout(() => {
            const dataURL = tempCanvas.toDataURL({
              format: "png",
              multiplier: 1,
            });
            dispatch(setProductPreviewMockupURL(dataURL));
            setProductImgURL(dataURL);

            tempCanvas.dispose();
            resolve(); // Resolve the promise after operations are complete
          }, 500); // Adjust timeout as necessary to ensure canvas has finished rendering
        })
        .catch((error) => {
          reject(error); // Reject the promise if there's an error
        });
    });
  }

  const handleImageLoad = async () => {
    const fabricCanvas = new fabric.Canvas(fabricCanvasPreviewRef.current, {
      width: 2000,
      height: 2000,
      enableRetinaScaling: false,
      preserveObjectStacking: true,
      interactive: true,
      backgroundColor: "rgba(119, 119, 119, 0.6)",
      stroke: "rgba(255,255,255,0.5)",
      strokeWidth: 5,
      shadow: "rgba(0,0,0,0.6) 5px 5px 5px",
    });

    setFabricGlobal(fabricCanvas);

    const canvasWidth = fabricCanvas.width;
    const canvasHeight = fabricCanvas.height;

    adjustCanvasSize(fabricCanvas);

    const loadImage = (url) => {
      return new Promise((resolve, reject) => {
        fabric.Image.fromURL(
          url,
          (img) => {
            resolve(img);
          },
          {
            crossOrigin: "anonymous",
            onError: (err) => reject(err),
          }
        );
      });
    };

    try {
      const [design, mockup] = await Promise.all([
        loadImage(designFileURL),
        loadImage(imgProduct),
      ]);

      const canvasAspect = canvasWidth / canvasHeight;
      const mockupAspect = mockup.width / mockup.height;

      // Scale mockup to fit the canvas
      if (canvasAspect >= mockupAspect) {
        mockup.scaleToHeight(canvasHeight);
      } else {
        mockup.scaleToWidth(canvasWidth);
      }

      mockup.set({
        left: (canvasWidth - mockup.getScaledWidth()) / 2,
        top: (canvasHeight - mockup.getScaledHeight()) / 2,
        selectable: false,
        evented: false,
      });

      if (mockup.getScaledWidth() >= mockup.getScaledHeight()) {
        design.scaleToHeight(mockup.getScaledHeight() / previewRatio);
      } else {
        design.scaleToWidth(mockup.getScaledWidth() / previewRatio);
      }

      design.set({
        left:
          mockup.left + (mockup.getScaledWidth() - design.getScaledWidth()) / 2,
        top:
          mockup.top +
          (mockup.getScaledHeight() - design.getScaledHeight()) /
            previewRatioTop,
        selectable: false,
        evented: false,
      });

      fabricCanvas.add(mockup);
      fabricCanvas.add(design);
      fabricCanvas.renderAll();

      await downloadCanvasAsImage(fabricCanvas);
    } catch (error) {
      console.error("Error uploading the design file", error);
    }
  };

  useEffect(() => {
    handleImageLoad();
  }, [fabricCanvasPreviewRef, designFileURL, imgProduct]);

  // useEffect(() => {
  //   if (fabricGlobal && fabricGlobal.getContext()) {
  //     if (props.confirmDesign) downloadCanvasAsImage(fabricGlobal);
  //   } else {
  //     console.log("fabricGlobal not found");
  //   }
  //   props.setConfirmDesign(false);
  // }, [props.confirmDesign]);

  // const handleButtonClick = () => {
  //   downloadCanvasAsImage(fabricGlobal);
  // };

  return (
    <Box>
      {/* <Button onClick={handleButtonClick}>click</Button> */}
      <canvas style={{ display: "none" }} ref={fabricCanvasPreviewRef}></canvas>
      {/* <img src={imgTest} /> */}
    </Box>
  );
};

export default CustomProductPreview;
