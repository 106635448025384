import React, { useEffect, useState } from "react";
import { Container, Typography, Box, Grid, Avatar, Paper } from "@mui/material";
import { useAuth } from "../AuthenticationContext";
import axios from "axios";
import axiosInstance from "../axiosInstance";
import api from "../axiosConfig";

const UserProfileDetails = () => {
  const { isAuthenticated } = useAuth();
  const [userDetails, setUserDetails] = useState(null);

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const token = localStorage.getItem("accessToken");
        // const response = await axios.get(
        //   "http://127.0.0.1:8000/auth/users/me",
        //   // "https://api.okayartist.com/auth/users/me",
        //   {
        //     headers: {
        //       Authorization: `Bearer ${token}`,
        //     },
        //   }
        // );
        // const response = await axiosInstance.get("auth/users/me");
        const response = await api.get("auth/users/me");
        setUserDetails(response.data);
      } catch (error) {
        console.error("Error fetching user details:", error);
      }
    };

    if (isAuthenticated) {
      fetchUserDetails();
    }
  }, [isAuthenticated]);

  if (!userDetails) {
    return (
      <Container maxWidth="sm">
        <Typography variant="h5" align="center">
          Loading user details...
        </Typography>
      </Container>
    );
  }

  return (
    <Container maxWidth="md">
      <Box mt={4}>
        <Box sx={{ p: 4 }}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={4}>
              <Avatar
                alt={userDetails.name}
                src={userDetails.profile_image}
                sx={{ width: 150, height: 150, mx: "auto" }}
              />
            </Grid>
            <Grid item xs={12} sm={8}>
              <Typography variant="h4" gutterBottom>
                {userDetails.name}
              </Typography>
              <Typography variant="body1" gutterBottom>
                <strong>Email:</strong> {userDetails.email}
              </Typography>
              <Typography variant="body1" gutterBottom>
                <strong>Phone:</strong> {userDetails.phone}
              </Typography>
              <Typography variant="body1" gutterBottom>
                <strong>Address:</strong> {userDetails.address}
              </Typography>
              <Typography variant="body1" gutterBottom>
                <strong>Joined:</strong>{" "}
                {new Date(userDetails.joined).toLocaleDateString()}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
};

export default UserProfileDetails;
