import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import { Box } from "@mui/material";
import ImageSlideShowComponent from "./ImageSlideShowComponent";
import CollectionList from "./CollectionList";
import FeaturedProductList from "./FeaturedProductList";
import FeaturedProductComponent from "./FeaturedProductComponent";
import ImageComparison from "./ImageComparison";
import { Helmet } from "react-helmet";

const HomePage = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        overflow: "hidden",
      }}
    >
      <Helmet>
        <title>Okay Artist</title>
        <meta
          name="description"
          content="Discover unique custom designs at Okay Artist."
        />
        <meta
          name="keywords"
          content="custom designs, t-shirts, ecommerce, okay artist"
        />
        <meta property="og:title" content="Okay Artist | Custom Designs" />
        <meta
          property="og:description"
          content="Discover unique custom designs at Okay Artist."
        />
        <meta
          property="og:image"
          content="https://product-images-okayartist.s3.amazonaws.com/generic-images/logo-for-meta-tags.jpg"
        />
        <meta property="og:url" content="https://www.okayartist.com/" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Okay Artist | Custom Designs" />
        <meta
          name="twitter:description"
          content="Discover unique custom designs at Okay Artist."
        />
        <meta
          name="twitter:image"
          content="https://product-images-okayartist.s3.amazonaws.com/generic-images/logo-for-meta-tags.jpg"
        />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <Header />
      {/* <FetchAPI /> */}
      {/* <h1>Home Page</h1>
      <Link to="/about">About</Link> */}

      <ImageSlideShowComponent />

      {/* <VideoComponent /> */}
      <CollectionList />
      <FeaturedProductList />
      <FeaturedProductComponent />
      <ImageComparison />

      <Footer></Footer>
    </Box>
  );
};

export default HomePage;
