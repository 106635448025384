import React, { useState } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  CircularProgress,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import OrderConfirmation from "./OrderConfirmation";
import { clearBasket } from "./redux-slices/BasketSlice";
import api from "./axiosConfig";
import PaymentPage from "./PaymentPage";
import { setAdress } from "./redux-slices/AddressSlice";

const CheckoutForm = (props) => {
  const dispatch = useDispatch();
  const items = useSelector((state) => state.basket.items);
  const totalOrderCost = useSelector((state) => state.basket.orderTotalCost);
  const shippingTotal = useSelector((state) => state.basket.shippingTotal);
  const itemsTotalCost = useSelector((state) => state.basket.itemsTotalCost);
  const [orderCreated, setOrderCreated] = useState(false);
  const [loading, setLoading] = useState(false); // Add loading state
  const [orderDetails, setOrderDetails] = useState(null);
  const [paymentCheck, setPaymentCheck] = useState(false);

  const [shippingDetails, setShippingDetails] = useState({
    name: "",
    address: "",
    city: "",
    state: "",
    postalCode: "",
    country: "",
    email: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setShippingDetails((prevDetails) => ({ ...prevDetails, [name]: value }));
  };

  // const handleSubmit = async (event) => {
  //   event.preventDefault();
  //   setLoading(true); // Set loading to true when the form is submitting

  //   const order = {
  //     shippingDetails,
  //     totalOrderCost,
  //     shippingTotal,
  //     itemsTotalCost,
  //     items,
  //   };

  //   try {
  //     const response = await api.post("/api/create-order/", order);
  //     console.log("Order created successfully:", response.data);

  //     setOrderCreated(true);
  //     dispatch(clearBasket());
  //   } catch (error) {
  //     console.error("Error creating order:", error);
  //   } finally {
  //     setLoading(false); // Set loading to false after the response is received
  //   }
  // };

  // const handleProceedPaymentClick = () => {
  //   dispatch(setAdress(shippingDetails));
  //   setPaymentCheck(true);
  // };

  return orderCreated ? (
    <OrderConfirmation />
  ) : (
    <>
      <form
      // onSubmit={handleSubmit}
      >
        {/* <Typography variant="h6" gutterBottom>
          Shipping Details
        </Typography>
        <Box mb={2}>
          <TextField
            label="Name"
            name="name"
            fullWidth
            required
            value={shippingDetails.name}
            onChange={handleInputChange}
            margin="normal"
          />
          <TextField
            label="Email"
            name="email"
            fullWidth
            required
            type="email"
            value={shippingDetails.email}
            onChange={handleInputChange}
            margin="normal"
          />
          <TextField
            label="Address"
            name="address"
            fullWidth
            required
            value={shippingDetails.address}
            onChange={handleInputChange}
            margin="normal"
          />
          <TextField
            label="City"
            name="city"
            fullWidth
            required
            value={shippingDetails.city}
            onChange={handleInputChange}
            margin="normal"
          />
          <TextField
            label="State"
            name="state"
            fullWidth
            required
            value={shippingDetails.state}
            onChange={handleInputChange}
            margin="normal"
          />
          <TextField
            label="Postal Code"
            name="postalCode"
            fullWidth
            required
            value={shippingDetails.postalCode}
            onChange={handleInputChange}
            margin="normal"
          />
          <TextField
            label="Country"
            name="country"
            fullWidth
            required
            value={shippingDetails.country}
            onChange={handleInputChange}
            margin="normal"
          />
        </Box> */}

        {/* <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={loading} // Disable the button while loading
        >
          Pay
        </Button> */}
        {/* {!paymentCheck && (
          <>
            <Button
              // type="submit"
              onClick={handleProceedPaymentClick}
              variant="contained"
              // sx={{ mt: 3, mb: 2 }}
              disabled={loading}
              color="primary"
              // endIcon={loading ? <CircularProgress size={24} /> : null}
            >
              {!loading ? "Proceed to Payment" : ""}
            </Button>
          </>
        )} */}

        <>
          <Typography variant="h6" gutterBottom>
            Payment Details
          </Typography>
          <Box mb={2}>
            <PaymentPage totalOrderCost={props.totalOrderCost} />
          </Box>
        </>
      </form>
    </>
  );
};

export default CheckoutForm;
