import React from "react";
import ReactBeforeSliderComponent from "react-before-after-slider-component";
import "react-before-after-slider-component/dist/build.css";
import { motion } from "framer-motion";
import { Typography } from "@mui/material";

const SECOND_IMAGE = {
  imageUrl:
    "https://product-images-okayartist.s3.amazonaws.com/home-page-images/image-bg.jpg",
};
const FIRST_IMAGE = {
  imageUrl:
    "https://product-images-okayartist.s3.amazonaws.com/home-page-images/image-bg2.jpg",
};

// Animation variants
const variants = {
  hidden: { opacity: 0, y: 50 }, // Start hidden with a vertical offset
  visible: (index) => ({
    opacity: 1,
    y: 0,
    transition: {
      delay: 0.2, // Add delay based on index to stagger the cards
      duration: 0.5,
      ease: "easeInOut",
    },
  }),
};

const ImageComparison = () => {
  return (
    <motion.div
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true, amount: 0.3 }} // Only animate once, when 30% of the element is visible
      //   custom={index}
      variants={variants}
    >
      <Typography
        variant="h2"
        gutterBottom
        sx={{
          color: "text.secondary",
          fontWeight: "bold",
          textAlign: "center",
        }}
      >
        Remove Backgrounds of Photos
      </Typography>
      <ReactBeforeSliderComponent
        firstImage={FIRST_IMAGE}
        secondImage={SECOND_IMAGE}
        delimiterColor={"#d2f2ff"}
      />
    </motion.div>
  );
};

export default ImageComparison;
