import React, { useState, useEffect } from "react";
import { Box, Button, IconButton, Stack, Typography } from "@mui/material";
import {
  ArrowBackIos,
  ArrowForwardIos,
  PlayArrow,
  Pause,
  RadioButtonChecked,
  RadioButtonUnchecked,
} from "@mui/icons-material";
import { useMediaQuery } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import theme from "../theme";
import { fetchFeaturedProduct } from "./fetchProductDetails";
import { useNavigate } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";

const ImageSlideShowComponent = () => {
  const navigate = useNavigate();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isPlaying, setIsPlaying] = useState(true);
  const [isLoading, setIsLoading] = useState(true); // For loading images
  const matchesXS = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [preloadedImages, setPreloadedImages] = useState([]); // Preloaded images
  const [direction, setDirection] = useState(0); // Direction for animation (-1 for left, 1 for right)

  // Fetch products
  useEffect(() => {
    const getProducts = async () => {
      try {
        const data = await fetchFeaturedProduct();
        setFilteredProducts(data);

        // Preload all images
        const images = data.map((product) => {
          const img = new Image();
          img.src = product.featuredImageURL;
          return img;
        });
        setPreloadedImages(images); // Store preloaded images
      } catch (error) {
        // Handle error
      } finally {
        setIsLoading(false);
      }
    };

    getProducts();
  }, []);

  // Auto-play functionality
  useEffect(() => {
    let timer;
    if (isPlaying) {
      timer = setInterval(() => {
        handleNext();
      }, 7000);
    }
    return () => clearInterval(timer);
  }, [isPlaying, filteredProducts.length]);

  const handlePrev = () => {
    setDirection(-1);
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? filteredProducts.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setDirection(1);
    setCurrentIndex((prevIndex) => (prevIndex + 1) % filteredProducts.length);
  };

  const handlePlayPause = () => {
    setIsPlaying((prev) => !prev);
  };

  const handleDragEnd = (event, info) => {
    if (info.offset.x > 100) {
      handlePrev();
    } else if (info.offset.x < -100) {
      handleNext();
    }
  };

  // Variants for motion animations based on direction
  const variants = {
    enter: (direction) => ({
      x: direction === 1 ? 500 : -500,
      opacity: 0,
      scale: 0.8,
    }),
    center: {
      x: 0,
      opacity: 1,
      scale: 1,
      transition: { duration: 0.5, ease: "easeInOut" },
    },
    exit: (direction) => ({
      x: direction === 1 ? -500 : 500,
      opacity: 0,
      scale: 0.8,
      transition: { duration: 0.5, ease: "easeInOut" },
    }),
  };

  return filteredProducts.length ? (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          width: "100%",
          height: "60vh",
          position: "relative",
          backgroundColor: "#d2f2ff",
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {isLoading ? (
            <Typography>Loading...</Typography>
          ) : (
            <AnimatePresence initial={false} custom={direction} mode="wait">
              <motion.img
                key={filteredProducts[currentIndex].listingId} // Ensure uniqueness
                width={"100%"}
                height={"100%"}
                src={filteredProducts[currentIndex].featuredImageURL}
                alt={filteredProducts[currentIndex].featuredImageTitle}
                style={{ objectFit: "cover" }}
                loading="lazy"
                initial="enter"
                animate="center"
                exit="exit"
                custom={direction}
                variants={variants}
                drag="x" // Enable drag on the x-axis
                dragConstraints={{ left: 0, right: 0 }} // Drag only horizontally
                onDragEnd={handleDragEnd} // Handle drag events
              />
            </AnimatePresence>
          )}
        </Box>

        {/* Navigation Arrows */}
        <Box
          sx={{
            position: "absolute",
            top: "95%",
            left: "30%",
            transform: "translateY(-50%)",
          }}
        >
          <IconButton onClick={handlePrev} sx={{ color: "primary.secondary" }}>
            <ArrowBackIos />
          </IconButton>
        </Box>
        <Box
          sx={{
            position: "absolute",
            top: "95%",
            right: "30%",
            transform: "translateY(-50%)",
          }}
        >
          <IconButton onClick={handleNext} sx={{ color: "primary.secondary" }}>
            <ArrowForwardIos />
          </IconButton>
        </Box>

        {/* Play/Pause Button */}
        <Box
          sx={{
            position: "absolute",
            top: "95%",
            right: "5%",
            transform: "translateY(-50%)",
          }}
        >
          <IconButton
            onClick={handlePlayPause}
            sx={{ color: "primary.secondary" }}
          >
            {isPlaying ? <Pause /> : <PlayArrow />}
          </IconButton>
        </Box>

        {/* Product Title and Shop Button */}
        <Stack direction={"column"} spacing={5}>
          <Typography
            variant={!matchesXS ? "h4" : "h6"}
            sx={{
              position: "absolute",
              top: "70%",
              left: "50%",
              transform: "translateX(-50%)",
              color: "white",
              textShadow: "2px 2px 4px rgba(0,0,0,0.6)",
            }}
          >
            {filteredProducts[currentIndex].featuredImageTitle}
          </Typography>
          <Button
            variant="outlined"
            fontSize="large"
            sx={{
              position: "absolute",
              top: "75%",
              left: "50%",
              transform: "translateX(-50%)",
              mb: "5%",
            }}
            onClick={() => {
              navigate(
                `/products/${filteredProducts[currentIndex].listingId}/${filteredProducts[currentIndex].designId}`
              );
            }}
          >
            Shop Now
          </Button>
        </Stack>

        {/* Dots Navigation */}
        <Box
          sx={{
            position: "absolute",
            top: "92%",
            left: "50%",
            transform: "translateX(-50%)",
          }}
        >
          {filteredProducts.map((_, index) => (
            <IconButton
              key={index}
              onClick={() => setCurrentIndex(index)}
              sx={{ color: "primary.secondary" }}
            >
              {index === currentIndex ? (
                <RadioButtonChecked fontSize="small" />
              ) : (
                <RadioButtonUnchecked fontSize="small" />
              )}
            </IconButton>
          ))}
        </Box>
      </Box>
    </ThemeProvider>
  ) : (
    ""
  );
};

export default ImageSlideShowComponent;
