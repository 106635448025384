import React, { useState } from "react";
import {
  Box,
  Grid,
  Typography,
  Card,
  CardMedia,
  CardContent,
  CardActionArea,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { fetchFeaturedProductsList } from "./fetchProductDetails";
import { motion } from "framer-motion";
import theme from "../theme";
import { ThemeProvider } from "@mui/material/styles";

const FeaturedProductList = () => {
  const navigate = useNavigate();
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [filteredProducts, setFilteredProducts] = useState([]);

  //fetch products from dynamodb
  React.useEffect(() => {
    const getProducts = async () => {
      try {
        const data = await fetchFeaturedProductsList();
        setFilteredProducts(data);
      } catch (error) {
        // handle error
      }
    };

    getProducts();
  }, []);

  // Animation variants
  const variants = {
    hidden: { opacity: 0, y: 50 }, // Start hidden with a vertical offset
    visible: (index) => ({
      opacity: 1,
      y: 0,
      transition: {
        delay: index * 0.2, // Add delay based on index to stagger the cards
        duration: 0.5,
        ease: "easeInOut",
      },
    }),
  };

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          width: "100%",
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          pt: 3,
          pb: 3,
          backgroundColor: "background.secondary",
        }}
      >
        <Typography
          variant="h2"
          gutterBottom
          sx={{ color: "text.secondary", fontWeight: "bold" }}
        >
          Best Selling Design Templates
        </Typography>
        <Typography variant="h6" color="textSecondary" gutterBottom>
          Customize with your photo
        </Typography>
        <Grid
          container
          spacing={2}
          justifyContent="center"
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          {filteredProducts.map((product, index) => (
            <Grid item xs={4} sm={4} md={3} key={index}>
              {/* Motion div to animate the card when it comes into view */}
              <motion.div
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.3 }} // Only animate once, when 30% of the element is visible
                custom={index}
                variants={variants}
              >
                <Card
                  variant="outlined"
                  onClick={() => {
                    navigate(
                      `/products/${product.listingId}/${product.designId}`
                    );
                  }}
                  sx={{
                    position: "relative",
                    textDecoration: "none",
                    overflow: "hidden",
                    borderRadius: "8px",
                    boxShadow: 3,
                    "&:hover": {
                      boxShadow: 6,
                      transition:
                        "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
                    },
                  }}
                >
                  <CardActionArea>
                    <CardMedia
                      component="img"
                      image={
                        hoveredIndex === index
                          ? product.images[0].img
                          : product.images[1].img
                      }
                      alt={product.title}
                      sx={{
                        borderRadius: "8px 8px 0 0",
                        transition: "transform 0.3s ease-in-out",
                      }}
                      onMouseEnter={() => setHoveredIndex(index)}
                      onMouseLeave={() => setHoveredIndex(null)}
                    />
                    <CardContent>
                      <Typography variant="h6" component="div">
                        {product.title}
                      </Typography>
                      <Typography variant="h6">
                        ${product.unitPrice[0].small}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </motion.div>
            </Grid>
          ))}
        </Grid>
      </Box>
    </ThemeProvider>
  );
};

export default FeaturedProductList;
